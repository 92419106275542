import * as R from 'ramda'
import {TJSONError} from '~/api/types'
import {areStrings} from '~/utils'

export type TAPIErrorKind =
  | 'formError'
  | 'invalidArguments'
  | 'invalidCSRF'
  | 'loggedOut'
  | 'unknown'

export interface IAPIErrorDataBase {
  kind: TAPIErrorKind
  statusCode: number
  statusText: string
  rawResponseData: any
}

export interface IAPIErrorDataFormError extends IAPIErrorDataBase {
  kind: 'formError'

  // key: fieldName of the form field, or just another label if not applicable
  // value: list of error messages. guaranteed to have at least one message by isJSONError
  formErrors: {
    [k: string]: string[]
  }
}

export interface IAPIErrorDataInvalidArguments extends IAPIErrorDataBase {
  kind: 'invalidArguments'
  invalidArgumentsError: TInvalidArgumentsError
}

export interface IAPIErrorDataInvalidCSRF extends IAPIErrorDataBase {
  kind: 'invalidCSRF'
}

export interface IAPIErrorDataLoggedOut extends IAPIErrorDataBase {
  kind: 'loggedOut'
}

export interface IAPIErrorDataUnknown extends IAPIErrorDataBase {
  kind: 'unknown'
  contextualMessage: string
}

export type TAPIErrorData =
  | IAPIErrorDataFormError
  | IAPIErrorDataInvalidArguments
  | IAPIErrorDataInvalidCSRF
  | IAPIErrorDataLoggedOut
  | IAPIErrorDataUnknown

/* Confirms the type-safety of `jsonError`s from the backend for conversion to APIFormError objects */
export const isJSONError = (e: any): e is TJSONError => {
  if (e instanceof Object && !(e instanceof Array)) {
    if (
      e['errors'] &&
      e['errors'] instanceof Object &&
      !(e['errors'] instanceof Array)
    ) {
      const errors = e['errors']
      const keys = Object.keys(errors)
      return (
        keys.length > 0 &&
        R.all((key: string) => {
          const innerErrors = errors[key]
          return (
            typeof key === 'string' &&
            innerErrors instanceof Array &&
            innerErrors.length > 0 &&
            areStrings(innerErrors)
          )
        }, keys)
      )
    }
  }
  return false
}

/* A type of error the backend sometimes returns, usually for custom-formatted strings that may fail to parse.
 *  For example: {
 *     "message": "Invalid Arguments",
 *     "errors": ["Model/CustomTypes.hs: Error parsing '123–12–3123' as a
 *               Social Security Number. Error: unexpected '–'\nexpecting digit\n"]
 *  }
 */
export type TInvalidArgumentsError = {
  message: 'Invalid Arguments'
  errors: string[]
}

export const isInvalidArgumentsError = (e: any): e is TInvalidArgumentsError => {
  return (
    !!e &&
    e.message === 'Invalid Arguments' &&
    Array.isArray(e.errors) &&
    areStrings(e.errors)
  )
}
