import {GetApi} from '~/api/client'
import globalDataMock from '~/api/endpoints/GetInitialData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TGetInitialDataResponse} from '~/api/generated/types/GetInitialData'

type TGetInitialDataParams = {}

export default class GetInitialData extends GetApi<
  TGetInitialDataParams,
  TGetInitialDataResponse
> {
  public static mockResponseWithMockConfig = () => {
    // TODO eventually configure the response here with MockConfig
    return globalDataMock
  }

  protected getMockResponse(params: TGetInitialDataParams): TGetInitialDataResponse {
    return GetInitialData.mockResponseWithMockConfig()
  }

  protected getPath(_: {}) {
    return serverEndpoints.getInitialData()
  }

  protected transformResponse(r: TGetInitialDataResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
