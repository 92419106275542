import classnames from 'classnames'
import React from 'react'

type TChildrenFunc = (hovered: boolean) => React.ReactNode | React.ReactNode[]

export interface IHoverTrackingDivProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode | React.ReactNode[] | TChildrenFunc
  hoveredClassName?: string
  divRef?: React.Ref<HTMLDivElement>
  onHoverChange?: (hovered: boolean) => void
}

interface IState {
  hovered: boolean
}

/** A div that tells it's children if it's hovered or not via a render prop. */
class HoverTrackingDivInternal extends React.Component<
  IHoverTrackingDivProps,
  IState
> {
  state: IState = {
    hovered: false,
  }

  componentWillUnmount() {
    this.props.onHoverChange?.(false)
  }

  render() {
    const {
      className,
      hoveredClassName,
      children,
      divRef,
      onHoverChange,
      ...divProps
    } = this.props
    return (
      <div
        {...divProps}
        ref={divRef}
        className={classnames(className, this.state.hovered && hoveredClassName)}
        onMouseEnter={e => {
          this.setState({hovered: true}, () => onHoverChange?.(true))
          this.props.onMouseEnter?.(e)
        }}
        onMouseLeave={e => {
          this.setState({hovered: false}, () => onHoverChange?.(false))
          this.props.onMouseLeave?.(e)
        }}
      >
        {typeof children === 'function'
          ? (children as TChildrenFunc)(this.state.hovered)
          : children}
      </div>
    )
  }
}

const HoverTrackingDiv = React.forwardRef<HTMLDivElement, IHoverTrackingDivProps>(
  (props, ref) => <HoverTrackingDivInternal divRef={ref} {...props} />
)
export default HoverTrackingDiv
