import * as R from 'ramda'
import APIError from '~/api/client/APIError/base'
import {IAPIErrorDataFormError} from '~/api/client/APIError/types'
import {safely} from '~/utils'

/**
 * Represents a `jsonError` from the backend. `formErrors` is the parsed jsonError object,
 * which has keys that should map to the params / formField names of the form submitted,
 * though sometimes there are other keys used as well.
 * The values of each key are a list of error messages (often we only show first one, only one is usually sent anyway)
 */
export default class APIFormError extends APIError
  implements IAPIErrorDataFormError {
  public readonly kind = 'formError'
  public readonly formErrors: {
    [k: string]: string[]
  }

  constructor(errorData: IAPIErrorDataFormError) {
    super(errorData)
    this.formErrors = errorData.formErrors
  }

  public errorKeys(): string[] {
    return R.keys(this.formErrors) as string[]
  }

  public errorKeySet(): Set<string> {
    return new Set(this.errorKeys())
  }

  public firstErrorMessage(): string {
    // guaranteed via isJSONError
    return this.formErrors[this.errorKeys()[0]][0]
  }

  public numberOfErrors(): number {
    return this.errorKeys().length
  }

  public soleErrorMessageOr(ifMany: string) {
    return this.numberOfErrors() === 1 ? this.firstErrorMessage() : ifMany
  }

  public errorsForKey(key: string): Array<string> | undefined {
    return this.formErrors[key]
  }

  public firstErrorForKey(key: string): string | undefined {
    return safely(this.formErrors[key], a => a[0])
  }

  public hasKey(key: string): boolean {
    return this.formErrors[key] !== undefined
  }

  public toHumanString(): string {
    return this.firstErrorMessage()
  }
}
