// Moved all constructors for APIError subclasses here to avoid circular dependency issues
// between the subclasses and the superclass
import {AxiosError} from 'axios'
import {OmitStrict} from 'type-zoo'
import APIError from '~/api/client/APIError/base'
import APIFormError from '~/api/client/APIError/APIFormError'
import APIInvalidArgumentsError from '~/api/client/APIError/APIInvalidArgumentsError'
import APIInvalidCSRFError from '~/api/client/APIError/APIInvalidCSRFError'
import APILoggedOutError from '~/api/client/APIError/APILoggedOutError'
import APIUnknownError from '~/api/client/APIError/APIUnknownError'
import {
  IAPIErrorDataBase,
  IAPIErrorDataFormError,
  IAPIErrorDataUnknown,
  isInvalidArgumentsError,
  isJSONError,
} from '~/api/client/APIError/types'
import {TJSONError} from '~/api/types'

type TAPIErrorDataCommonPieces = OmitStrict<IAPIErrorDataBase, 'kind'>

export const constructMockServerError = (statusCode: number) => {
  return new APIUnknownError({
    kind: 'unknown',
    statusCode,
    statusText: '',
    rawResponseData: undefined,
    contextualMessage: 'Mock error occurred during mock API call.',
  })
}

export const constructMockRequestFailure = (
  jsonError: TJSONError,
  statusCode: number = 400
) => {
  return new APIFormError(
    fromJSONError(jsonError, statusCode, 'Mock request failure')
  )
}

const unreachableServerErrorData = (): IAPIErrorDataUnknown => {
  return {
    kind: 'unknown',
    statusCode: 0,
    statusText: '',
    rawResponseData: undefined,
    contextualMessage:
      "We couldn't reach our servers. Please try refreshing the page.",
  }
}

const fromJSONError = (
  jsonError: TJSONError,
  statusCode: number,
  statusText: string
): IAPIErrorDataFormError => {
  return {
    kind: 'formError',
    statusCode,
    statusText,
    formErrors: jsonError.errors,
    rawResponseData: jsonError,
  }
}

const isCSRFError = ({
  rawResponseData,
  statusCode,
}: TAPIErrorDataCommonPieces): boolean => {
  return (
    statusCode === 403 &&
    !!rawResponseData &&
    typeof rawResponseData.message === 'string' &&
    rawResponseData.message.includes('A valid CSRF token')
  )
}

/**
 * Sorts out various errors we run into when hitting API endpoints
 * into one of the subclasses of APIError, depending on the error.
 */
export const buildFromAxiosError = (
  // don't want to assume the error actually is of shape AxiosError and cause an exception
  e: Partial<AxiosError>,
  requestPath: string // URL
): APIError => {
  if (e.response) {
    // From the Axios docs: The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const statusCode = e.response.status || 0
    const statusText = e.response.statusText || ''
    const rawResponseData = e.response.data

    let commonErrorData: TAPIErrorDataCommonPieces = {
      statusCode,
      statusText,
      rawResponseData,
    }

    if (
      statusCode === 401 &&
      ((isJSONError(rawResponseData) && rawResponseData.errors.loggedOut) ||
        rawResponseData?.message === 'Not logged in') // a Yesod default error we can get with cookie issues
    ) {
      return new APILoggedOutError({
        ...commonErrorData,
        kind: 'loggedOut',
      })
    } else if (isCSRFError(commonErrorData)) {
      return new APIInvalidCSRFError({
        ...commonErrorData,
        kind: 'invalidCSRF',
      })
    } else if (isJSONError(rawResponseData)) {
      // the error is in a shape we expect for form entry errors - a 'jsonError' from the backend
      return new APIFormError({
        ...fromJSONError(rawResponseData, statusCode, statusText),
      })
    } else if (isInvalidArgumentsError(e.response.data)) {
      // the error is in another shape we expect for form entry errors
      return new APIInvalidArgumentsError({
        ...commonErrorData,
        kind: 'invalidArguments',
        invalidArgumentsError: e.response.data,
      })
    } else {
      // some other error response we don't understand / treat specially yet.
      // report 5xx errors to bugsnag
      if (statusCode > 499) {
        // reportFailedRequestToBugsnag(
        //   e,
        //   `Request to MWB failed with code ${statusCode}`,
        //   requestPath
        // )
      }

      console.error('Failed to load results from server.', {statusCode, statusText})

      return new APIUnknownError({
        ...commonErrorData,
        kind: 'unknown',
        contextualMessage:
          "We're having difficulty connecting to our server. Please try again.",
      })
    }
  } else if (e.request) {
    // From the Axios docs: The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser

    // this has the potential to annoy us if users have spotty internet connections...
    // reportFailedRequestToBugsnag(
    //   e,
    //   `Request to MWB received no response.`,
    //   requestPath
    // )

    return new APIUnknownError(unreachableServerErrorData())
  } else {
    // From the Axios docs: Something happened in setting up the request that triggered an Error

    // notifyBugsnag(
    //   {
    //     errorClass: 'mwb',
    //     errorMessage: 'Request to MWB could not be constructed.',
    //   },
    //   {
    //     metaData: {
    //       path: requestPath,
    //       error: {name: e.name, message: e.message, stack: e.stack},
    //     },
    //   }
    // )
    return new APIUnknownError(unreachableServerErrorData())
  }
}
