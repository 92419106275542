import APIError from '~/api/client/APIError/base'
import {IAPIErrorDataUnknown} from '~/api/client/APIError/types'

/**
 * This is a catch-all for all API errors that don't meet the criteria for any of our other APIError
 * subclasses. It does not necessarily represent a 5xx error or other unexpected issue, but often will.
 */
export default class APIUnknownError extends APIError
  implements IAPIErrorDataUnknown {
  public readonly kind = 'unknown'
  public readonly contextualMessage: string

  constructor(errorData: IAPIErrorDataUnknown) {
    super(errorData)
    this.contextualMessage = errorData.contextualMessage
  }

  public toHumanString(): string {
    return this.contextualMessage
  }
}
