import {GetApi} from '~/api/client'
import dashboardDataMock from '~/api/endpoints/dashboard/GetDashboardData/mock'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import {serverEndpoints} from '~/api/generated/endpoints'

export default class GetDashboardData extends GetApi<
  {},
  TDashboardDataResponse,
  {}
> {
  public static mockResponseWithMockConfig = () => {
    // TODO eventually configure the response here with MockConfig
    return dashboardDataMock
  }

  protected getMockResponse(params: {}): TDashboardDataResponse {
    return GetDashboardData.mockResponseWithMockConfig()
  }

  protected getPath(_: {}) {
    return serverEndpoints.dashboardData()
  }

  protected transformResponse(r: TDashboardDataResponse) {
    return r
  }

  public mocksRequireLogin() {
    return true
  }
}
