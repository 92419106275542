import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import ProvideFormBase, {
  IFieldValues,
  IFormStatus,
  IGenerateFormFieldProps,
  IProvideFormBaseProps,
  TProvideFormNormalConfig,
} from '~/components/ProvideForm/base'
import {IFormProps} from './types'

export type TProvideFormOnSubmitNormalExtras<
  FormFieldsType extends IFieldValues,
  SubmissionContextType
> = {
  submissionContext?: SubmissionContextType
  formStatus: IFormStatus<FormFieldsType>
}

export type TProvideFormOnSubmitNormal<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> = (
  formState: FormFieldsType,
  extras: TProvideFormOnSubmitNormalExtras<FormFieldsType, SubmissionContextType>
) => Promise<PromiseResultType>

export type TProvideFormToFormFieldsNormal<
  FormFieldsType extends IFieldValues,
  SubmissionContextType
> = (
  generateFormFieldProps: IGenerateFormFieldProps<
    FormFieldsType,
    SubmissionContextType
  >,
  formProps: IFormProps<FormFieldsType, SubmissionContextType>
) => JSX.Element | JSX.Element[]

// to form the IProps for ProvideForm we take the props of the base and
// first remove the two "generic" versions that work for ProvideForm and ProvideFormIncremental
// then we respecify the props for those two for the specific version that works only with ProvideForm
interface IProps<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> extends OmitStrict<
    IProvideFormBaseProps<FormFieldsType, PromiseResultType, SubmissionContextType>,
    'formConfig'
  > {
  onSubmit: TProvideFormOnSubmitNormal<
    FormFieldsType,
    PromiseResultType,
    SubmissionContextType
  >
  toFormFields: TProvideFormToFormFieldsNormal<FormFieldsType, SubmissionContextType>
}

export default class ProvideForm<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType = undefined
> extends React.Component<
  IProps<FormFieldsType, PromiseResultType, SubmissionContextType>
> {
  render() {
    const {onSubmit, toFormFields, ...provideFormProps} = this.props
    const formConfig: TProvideFormNormalConfig<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    > = {
      type: 'normal',
      submit: onSubmit,
      toFormFields,
    }
    return (
      <ProvideFormBase<FormFieldsType, PromiseResultType, SubmissionContextType>
        {...provideFormProps}
        formConfig={formConfig}
      />
    )
  }
}
