import * as React from 'react'
import {safely} from '~/utils'

interface IProps {
  height?: string
  width?: string
  className?: string
}

/** This component is everything you imagine it is and nothing more */
const Spacer = (props: IProps) => {
  let {width, height} = props
  const addPx = (str: string | undefined) =>
    safely(str, s => (/^\d+$/.test(s) ? s + 'px' : s))

  return (
    <div
      style={{
        width: `${addPx(width) || '100%'}`,
        height: `${addPx(height) || '100%'}`,
      }}
      className={props.className}
    />
  )
}

export default Spacer
