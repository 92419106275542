import * as R from 'ramda'
import * as React from 'react'
import LoadingScreen, {TLoadingState} from '~/components/LoadingScreen'

interface IProps<PromiseResult, PromiseProps = undefined> {
  /** Props passed to the generatePromise function.
   *  When the value of this changes (compared by Ramda.equals), a new promise will be generated.
   */
  promiseProps: PromiseProps
  /** Function to generate a promise from props. This should be a pure function. */
  generatePromise: (props: PromiseProps) => Promise<PromiseResult>

  /**
   * Provider function called with the result of a successful promise. Return value will be rendered.
   * If you don't provide this function, you can manually use onSuccess instead to get the result.
   * A default LoadingScreen UI is shown during loading states instead of the children provided here.
   */
  then?: (result: PromiseResult) => JSX.Element | JSX.Element[]

  /**
   * Provider function called with the result of a failed promise. Return value will be rendered.
   * If you don't provide this function, you can manually use onFailed instead to get the error.
   * A default LoadingScreen UI is shown during loading states instead of the children provided here.
   */
  catch?: (error: any) => JSX.Element | JSX.Element[]

  /**
   * Function called with the result of a successful promise, useful to set state outside of render().
   * It will be called before `then()` so you can save your state before the children of `then()` render.
   */
  onSuccess?: (result: PromiseResult) => void
  onLoading?: () => void

  /**
   * Function called with the error of a failed promise, useful to set state outside of render().
   * It will be called before `catch()` so you can save your state before the children of `catch()` render.
   */
  onFailed?: (error: any) => void
}

interface IState<PromiseResult> {
  loadState: TLoadingState

  promiseResult?: PromiseResult // only set when LoadState === 'success'
  promiseError?: any // only set when LoadState === 'failed'
}

/**
 * This class:
 *   * 'Cancels' promises on unmount (to avoid setting state after unmount)
 *   * Re-requests promises when the inputs to the promise change
 *   * Wraps the state of a requested promise with callbacks
 *   * Optionally uses the provider pattern to provide a loading UI to
 *     children components that depend on the result of the promise
 */
class PromiseManager<
  PromiseResult,
  PromiseProps = undefined
> extends React.Component<
  IProps<PromiseResult, PromiseProps>,
  IState<PromiseResult>
> {
  state: IState<PromiseResult> = {
    loadState: 'loading',
  }

  /** https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
   *  They suggest creating cancellable promises
   *  We could do that, but it's kind of a hassle to type and functionally equivalent.
   *  If you find yourself copying this pattern, probably create cancellable promises instead.
   */
  _mounted = false

  componentDidMount() {
    this._mounted = true
    this.onPromisePropsChanged()
  }

  componentDidUpdate(
    prevProps: IProps<PromiseResult, PromiseProps>,
    prevState: IState<PromiseResult>
  ): void {
    if (!R.equals(prevProps.promiseProps, this.props.promiseProps)) {
      this.onPromisePropsChanged()
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  onPromisePropsChanged() {
    this.setState({loadState: 'loading'}, () => {
      this.props.onLoading?.()

      // Store the props at the time of request to help prevent race conditions
      const promiseProps = this.props.promiseProps
      const promise = this.props.generatePromise(promiseProps)
      promise
        .then(promiseResult => {
          if (this._mounted && R.equals(promiseProps, this.props.promiseProps)) {
            this.props.onSuccess?.(promiseResult)
            this.setState({promiseResult, loadState: 'success'})
          }
        })
        .catch(promiseError => {
          if (this._mounted && R.equals(promiseProps, this.props.promiseProps)) {
            console.warn('Error in promise manager: ', promiseError)
            this.props.onFailed?.(promiseError)
            this.setState({promiseError, loadState: 'failed'})
          }
        })
    })
  }

  render() {
    const {then, catch: catchFunc} = this.props
    if (!then && !catchFunc) {
      return null
    }

    return (
      <LoadingScreen
        loadState={this.state.loadState}
        renderChildrenOnFailure={!!catchFunc}
      >
        {this.state.loadState === 'success' &&
          then &&
          then(this.state.promiseResult!)}
        {this.state.loadState === 'failed' &&
          catchFunc &&
          catchFunc(this.state.promiseError)}
      </LoadingScreen>
    )
  }
}

export default PromiseManager
