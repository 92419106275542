import * as RA from 'ramda-adjunct'

export type NEList<T> = [T, ...T[]]
export type NEListInitializer<T> = T | NEList<T>
export type NEListUnsafeInitializer<T> = NEListInitializer<T> | T[]

/** Get a new NEList back from one or many items */
export const create = <T>(items: NEListInitializer<T>): NEList<T> => {
  return RA.ensureArray(items) as NEList<T>
}

/** Get a new NEList back from one or many items.
 * This is `unsafe` because there are no built-in guarantees that the list is not empty
 */
export const createUnsafe = <T>(items: NEListUnsafeInitializer<T>): NEList<T> => {
  return create(items as NEList<T>)
}

/** Get a new NEList back from one or many items.
 * This is `safe` because it will return `undefined` if the given list is empty
 */
export const createSafe = <T>(
  items: NEListUnsafeInitializer<T>
): NEList<T> | undefined => {
  const l = createUnsafe(items)
  return l.length ? l : undefined
}

/** Get the member of the NEList at i. Returns the last element if i overflows the size of the list. */
export const atOrLast = <T>(i: number, list: NEList<T>): T => {
  const size = list.length
  return i >= size ? list[size - 1] : list[i]
}

export const head = <T>(list: NEList<T>): T => list[0]
export const tail = <T>(list: NEList<T>): T[] => list.slice(1)
export const tailNE = <T>(list: NEList<T>): NEList<T> | undefined =>
  createSafe(tail(list))

export const last = <T>(list: NEList<T>): T => list[list.length - 1]
export const notLast = <T>(list: NEList<T>): T[] => list.slice(0, list.length - 1)
export const notLastNE = <T>(list: NEList<T>): NEList<T> | undefined =>
  createSafe(notLast(list))

// Testing out overloads for merging heterogeneous lists like
//   const propertiesAndResidences = NEL.push(
//     properties,
//     model.residences,
//   )
//   but it isn't playing nice, leaving it for later for fun
//
// // export const push = <T>(...items: T[]) => (list: NEList<T>): NEList<T> => [
// //   ...list,
// //   ...items,
// // ]
//
// // export function push<T>(items: T[]): ((list: NEList<T>) => NEList<T>)
// export function push<T, U>(items: T[]): ((list: NEList<U>) => [U, ...(U|T)[]])
// export function push<T>(...items: T[]): ((list: NEList<T>) => NEList<T>)
// export function push<T, U>(...items: T[]): ((list: NEList<U>) => [U, ...(U|T)[]]) {
//   return ((list: [U, ...(U|T)[]]) => [
//     ...list,
//     ...items,
//   ])
// }
//
// // export function push2<T>(items: T[]): ((list: NEList<T>) => NEList<T>)
// export function push2<T, U>(list: NEList<U>): ((items: T[]) => [U, ...(U|T)[]])
// export function push2<T>(...items: T[]): ((list: NEList<T>) => NEList<T>)
// export function push2<T, U>(...items: T[]): ((list: NEList<U>) => [U, ...(U|T)[]]) {
//   return ((list: [U, ...(U|T)[]]) => [
//     ...list,
//     ...items,
//   ])
// }
