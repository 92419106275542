import classnames from 'classnames'
import * as React from 'react'
import styles from './styles.module.css'

const LoadingDots = ({className, width}: {className?: string; width?: number}) => (
  <div
    className={classnames({
      [styles['loading_spinner-wrap']]: true,
      [className || '']: true,
    })}
  >
    <svg
      className={styles['loading_spinner']}
      width={width || '60'}
      height="20"
      viewBox="0 0 60 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="16" r="4" />
      <circle cx="30" cy="16" r="4" />
      <circle cx="53" cy="16" r="4" />
    </svg>
  </div>
)

export default LoadingDots
