import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'

// response is a success code 204. Though we don't use that fact anywhere, yet
export default class SignOut extends PostApi<{}, {}> {
  protected getPath() {
    return serverEndpoints.logout()
  }

  protected getMockResponse() {
    return {}
  }

  protected transformResponse(response: {}) {
    return response
  }

  protected successShouldResetSessionTimer() {
    return false
  }

  public mocksRequireLogin() {
    return true
  }
}
