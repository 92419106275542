import React from 'react'
import {OmitStrict} from 'type-zoo/types'
import {IFormProps} from '~/components/ProvideForm/types'
import DSButton, {IDSButtonProps} from '~/design-system/Button'

interface IFormSubmissionButtonProps
  extends OmitStrict<IDSButtonProps, 'disabled' | 'appearDisabled' | 'loading'> {
  formProps: IFormProps<any>

  alsoDisabledWhen?: boolean
  alsoAppearDisabledWhen?: boolean
  alsoLoadingWhen?: boolean
}

/**
 * A button convenient for form submission. It automatically controls the Submit
 * button's `loading`, `disabled`, and `appearedDisabled` states with respect to
 * form errors & form submission. It prevents problems such as duplicate form
 * submission. If additional form criteria should affect the Submit Button's state,
 * it can be handled by, e.g.,
 * `alsoDisabledWhen={!formProps.haveAnyFieldsChangedFromDefault()}`. Frequently
 * should be paired with the `FormCancelButton`.
 */
export default class FormSubmissionButton extends React.Component<
  IFormSubmissionButtonProps
> {
  render() {
    const {
      formProps,
      alsoAppearDisabledWhen,
      alsoDisabledWhen,
      alsoLoadingWhen,
      onClick,
      ...restProps
    } = this.props

    const isButtonDisabled = alsoDisabledWhen || formProps.isFormSubmitting
    const doesButtonAppearDisabled = alsoAppearDisabledWhen || formProps.anyErrors
    const isLoading = alsoLoadingWhen || formProps.isFormSubmitting

    return (
      <DSButton
        onClick={onClick ? onClick : () => formProps.onSubmit()}
        loading={isLoading}
        appearDisabled={doesButtonAppearDisabled}
        disabled={isButtonDisabled}
        {...restProps}
      >
        {this.props.children}
      </DSButton>
    )
  }
}
