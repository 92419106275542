import memoizeOne from 'memoize-one'
import * as R from 'ramda'
import * as React from 'react'
import * as NEL from '~/utils/NEList'

export type TPollingState =
  | 'firstPoll'
  | 'polling'
  | 'waitingAfterSuccess'
  | 'waitingAfterFailure'
  | 'stopped'

/**
 * A PromisePoller is similar to a PromiseManager in that it will repeatedly call a given Promise for you
 * every time the parameters to the Promise change (`promiseProps`), e.g. the JSON request body of an API request.
 *
 * However, the PromisePoller will also continuously call the Promise again on a fixed schedule.
 */
export interface IPromisePollerProps<
  PromiseResult,
  PromiseProps = undefined,
  PromiseFailureType = any
> {
  /** Props passed to the generatePromise function.
   *  When the value of this changes (compared by Ramda.equals), a new promise will be generated.
   */
  promiseProps: PromiseProps
  /** Function to generate a promise from props. This should be a pure function. */
  generatePromise: (props: PromiseProps) => Promise<PromiseResult>

  /** how often to re-request the promise automatically
   *
   * provide optional different timings to be used after a failure via an array here.
   * the array allows a falloff timing scheme, where [0] is the normal delay, [1] is the delay after 1 error, and so on.
   *
   * use a negative number as the frequency to stop polling completely. you can restart the polling after this
   * by resetting the react key of this component or changing restartKey.
   * */
  frequencyMs: NEL.NEListInitializer<number>

  /**
   * Set this to some externally-changing value that, if/when changed, indicates
   * to the poller that it should restart polling in the event it had previously stopped.
   * For example, if the server connection died for a while, but the user does some other
   * server call successfully outside of the poller, we can/should resume polling.
   */
  restartKey?: number | string | boolean

  /**
   * When `true`, the poller will stop its timer, and any in-flight requests will have their responses thrown out.
   * Useful if another component is currently waiting on a promise that returns the same / related data as this poller.
   * Without this, you might receive "stale" data that you'd rather ignore while waiting for another request to return.
   * Upon unpausing, polling will resume, restarting at the beginning of `frequencyMs`.
   */
  pause?: boolean

  /**
   * Function called with the result of a successful promise.
   */
  onSuccess?: (result: PromiseResult) => void
  onPolling?: () => void

  /**
   * Function called with the error of a failed promise. Polling will continue.
   */
  onFailed?: (error: PromiseFailureType) => void

  children?: React.ReactNode | React.ReactNodeArray

  pollImmediatelyOnMount: boolean
}

interface IState {
  pollingState: TPollingState
  retries: number
}

/**
 * This class:
 *   * 'Cancels' promises on unmount (to avoid setting state after unmount) and if `pause`d
 *   * Re-requests promises when the inputs to the promise change and if un`pause`d
 *   * Wraps the state of a requested promise with callbacks
 */
class PromisePoller<
  PromiseResult,
  PromiseProps = undefined,
  PromiseFailureType = any
> extends React.Component<
  IPromisePollerProps<PromiseResult, PromiseProps, PromiseFailureType>,
  IState
> {
  pollingTimer: number | null = null
  state: IState = {
    pollingState: 'firstPoll',
    retries: 0,
  }
  // call this function (if present) to cancel an in-flight promise.
  cancelPromise: undefined | (() => void) = undefined
  // Something globally unique we can resolve cancelled promises with & compare against later.
  readonly cancelSymbol = Symbol('PromisePoller cancellable promise symbol')

  // allows parent to be lazy and give us a non-memoized timings array
  // in their JSX props like frequencyMs={[1,2,3]}
  // without making us re-render a lot
  readonly memoizedTimings = memoizeOne(NEL.create, R.equals)
  readonly timings = () => this.memoizedTimings(this.props.frequencyMs)

  startPollingTimer = (delayMs: number) => {
    this.clearPollingTimer()

    this.pollingTimer = window.setTimeout(() => {
      this.executePromise()
    }, delayMs)
  }

  clearPollingTimer() {
    if (this.pollingTimer) {
      clearTimeout(this.pollingTimer)
    }
  }

  componentDidMount() {
    if (this.props.pollImmediatelyOnMount) {
      this.executePromise()
    } else {
      this.startPollingTimer(NEL.head(this.timings()))
    }
  }

  componentDidUpdate(
    prevProps: IPromisePollerProps<PromiseResult, PromiseProps>,
    prevState: IState
  ): void {
    let delay: number
    let shouldExecute = false

    if (!prevProps.pause && this.props.pause) {
      // newly paused
      this.clearPollingTimer()
      shouldExecute = false
      this.cancelPromise?.()
    } else if (prevProps.pause && !this.props.pause) {
      // newly unpaused
      shouldExecute = true
      delay = NEL.head(this.timings()) // assuming there was just a success to change pause to false, could be 0 too.
    } else if (!R.equals(prevProps.promiseProps, this.props.promiseProps)) {
      // promise props changed, execute next poll immediately
      shouldExecute = true
      delay = 0
    } else if (this.props.restartKey !== prevProps.restartKey) {
      // parent wants us to reset ourselves if we're stopped
      shouldExecute = new Set<TPollingState>(['stopped', 'waitingAfterFailure']).has(
        this.state.pollingState
      )
      delay = NEL.head(this.timings()) // assuming there was just a success to change the restartKey, could be 0 too.
    }

    if (shouldExecute) {
      this.setState(
        {
          retries: 0,
        },
        () => this.startPollingTimer(delay)
      )
    }
  }

  componentWillUnmount() {
    this.clearPollingTimer()
    this.cancelPromise?.()
  }

  executePromise = () => {
    if (this.props.pause) {
      return
    }

    const timings = this.timings()

    this.setState({pollingState: 'polling'}, () => {
      this.props.onPolling?.()

      // Store the props at the time of request to help prevent race conditions
      const promiseProps = this.props.promiseProps
      const promise = this.props.generatePromise(promiseProps)

      // Make the promise cancellable by racing it with this one. The first promise to finish is the only one resolved.
      // We cancel the promise if our parent `pause`s, or if we unmount.
      const cancellable: Promise<Symbol> = new Promise(resolve => {
        this.cancelPromise = () => resolve(this.cancelSymbol)
      })

      Promise.race<PromiseResult | Symbol>([cancellable, promise])
        .then(promiseResult => {
          if (promiseResult === this.cancelSymbol) {
            return
          }
          this.cancelPromise = undefined

          if (R.equals(promiseProps, this.props.promiseProps)) {
            this.setState(
              {
                pollingState: 'waitingAfterSuccess',
                retries: 0,
              },
              () => {
                this.props.onSuccess?.(promiseResult as PromiseResult)
                this.startPollingTimer(NEL.head(timings))
              }
            )
          }
        })
        .catch(promiseError => {
          if (R.equals(promiseProps, this.props.promiseProps)) {
            this.setState(
              prevState => {
                const incrRetries = prevState.retries + 1
                const newDelay = NEL.atOrLast(incrRetries, timings)

                return {
                  pollingState: newDelay < 0 ? 'stopped' : 'waitingAfterFailure',
                  retries: incrRetries,
                }
              },
              () => {
                this.props.onFailed?.(promiseError)

                if (this.state.pollingState === 'waitingAfterFailure') {
                  this.startPollingTimer(NEL.atOrLast(this.state.retries, timings))
                }
              }
            )
          }
        })
    })
  }

  render() {
    return this.props.children ?? null
  }
}

export default PromisePoller
