import * as DateFns from 'date-fns'
import * as R from 'ramda'
import * as C from '~/utils/Comparable'
import Day from '~/utils/Day'
import * as Equatable from '~/utils/Equatable'

export const isLeapYear = (year: number) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

class Month implements C.IComparable<Month>, Equatable.IEquatable {
  year: number
  month: number
  constructor(year: number, month: number) {
    // this.month is 1 indexed, vs Javascript's Date's 0-indexed months
    if (month < 1 || month > 12) {
      throw new Error('Invalid month: ' + month)
    }
    this.year = year
    this.month = month
  }

  public to3LetterName() {
    return DateFns.format(this.toFirstDay().toDate(), 'MMM')
  }

  public toMonthName() {
    return DateFns.format(this.toFirstDay().toDate(), 'MMMM')
  }

  public to2DigitMonth() {
    return DateFns.format(this.toFirstDay().toDate(), 'MM')
  }

  public toComponents() {
    return [this.year, this.month]
  }

  public compare(month: Month): C.CompareResult {
    return C.compareArrays(this.toComponents(), month.toComponents())
  }

  equals = (other: any): boolean => {
    if (other && other.constructor === Month) {
      return this.compare(other) === C.CompareResult.EQ
    } else {
      return false
    }
  }

  // This is a count of hypothetical gregorian months extrapolated back to
  // 0 = January in 1BCE
  public toCEIndex() {
    return this.year * 12 + this.month - 1
  }

  public static fromCEIndex(ceIndex: number) {
    return new Month(Math.floor(ceIndex / 12), Math.floor(ceIndex % 12) + 1)
  }

  public static rangeInclusive(start: Month, end: Month): Month[] {
    return R.range(start.toCEIndex(), end.toCEIndex() + 1).map(ord =>
      Month.fromCEIndex(ord)
    )
  }

  /*
   * [start, end)
   */
  public static rangeInEx(start: Month, end: Month): Month[] {
    return R.range(start.toCEIndex(), end.toCEIndex()).map(ord =>
      Month.fromCEIndex(ord)
    )
  }

  public static monthsForYear(year: number): Month[] {
    return R.range(1, 13).map(month => new Month(year, month))
  }

  public static currentMonth(): Month {
    return Month.fromDate(new Date())
  }

  public static fromDate(date: Date): Month {
    return new Month(date.getFullYear(), date.getMonth() + 1)
  }

  public addMonths(months: number): Month {
    return Month.fromCEIndex(this.toCEIndex() + months)
  }

  public subMonths(months: number): Month {
    return this.addMonths(months * -1)
  }

  public toFirstDay(): Day {
    return new Day(this.year, this.month, 1)
  }

  public toLastDay(): Day {
    const lastDate = DateFns.lastDayOfMonth(this.toFirstDay().toDate())
    return Day.fromDate(lastDate)
  }

  public allDays(): Day[] {
    const first = this.toFirstDay()
    return R.range(0, this.numberOfDays()).map(i => first.addDays(i))
  }

  public numberOfDays(): number {
    const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    return this.month === 2
      ? isLeapYear(this.year)
        ? 29
        : 28
      : days[this.month - 1]
  }

  // used by JS to facilitate the comparison operators i.e. `<` `>=`, but notably not for `==` nor `===`
  valueOf(): number {
    return this.toCEIndex()
  }
}

export default Month
