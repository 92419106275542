/* eslint-disable-next-line no-shadow */
export enum CompareResult {
  LT = -1,
  EQ = 0,
  GT = 1,
}

export interface IComparable<T> {
  compare(other: IComparable<T>): CompareResult
}

export const lessThan = <T extends IComparable<T>>(a: T, b: T) => {
  return a.compare(b) === CompareResult.LT
}

export const lessThanOrEqual = <T extends IComparable<T>>(a: T, b: T) => {
  const comparison = a.compare(b)
  return comparison === CompareResult.LT || comparison === CompareResult.EQ
}

export const equals = <T extends IComparable<T>>(a: T, b: T) => {
  return a.compare(b) === CompareResult.EQ
}

export const greaterThanOrEqual = <T extends IComparable<T>>(a: T, b: T) => {
  const comparison = a.compare(b)
  return comparison === CompareResult.GT || comparison === CompareResult.EQ
}

export const greaterThan = <T extends IComparable<T>>(a: T, b: T) => {
  return a.compare(b) === CompareResult.GT
}

export const inRangeInclusive = <T extends IComparable<T>>(
  minimum: T,
  element: T,
  maximum: T
) => {
  return greaterThanOrEqual(element, minimum) && lessThanOrEqual(element, maximum)
}

export const inRangeExclusive = <T extends IComparable<T>>(
  minimum: T,
  element: T,
  maximum: T
) => {
  return greaterThan(element, minimum) && lessThan(element, maximum)
}

export const inRangeExIn = <T extends IComparable<T>>(
  minimum: T,
  element: T,
  maximum: T
) => {
  return greaterThan(element, minimum) && lessThanOrEqual(element, maximum)
}

export const inRangeInEx = <T extends IComparable<T>>(
  minimum: T,
  element: T,
  maximum: T
) => {
  return greaterThanOrEqual(element, minimum) && lessThan(element, maximum)
}

export const clamp = <T extends IComparable<T>>(
  minimum: T,
  element: T,
  maximum: T
) => {
  if (lessThan(element, minimum)) {
    return minimum
  } else if (greaterThan(element, maximum)) {
    return maximum
  } else {
    return element
  }
}

export const max = <T extends IComparable<T>>(a: T, b: T) => {
  return greaterThan(a, b) ? a : b
}

export const min = <T extends IComparable<T>>(a: T, b: T) => {
  return lessThan(a, b) ? a : b
}

// Utility

export const compareArrays = (xs: number[], ys: number[]): CompareResult => {
  if (xs.length !== ys.length) {
    throw new Error(
      'compareArrays is intended to be used on arrays of the same length'
    )
  }
  for (let i = 0; i < xs.length; i++) {
    const x = xs[i]
    const y = ys[i]
    if (x < y) {
      return CompareResult.LT
    } else if (x > y) {
      return CompareResult.GT
    }
  }
  return CompareResult.EQ
}

export const contains = <T extends IComparable<T>>(elem: T, list: T[]): boolean => {
  for (const listItem of list) {
    if (elem.compare(listItem) === CompareResult.EQ) {
      return true
    }
  }
  return false
}

export default IComparable
