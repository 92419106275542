import APIError from '~/api/client/APIError/base'
import {IAPIErrorDataInvalidArguments} from '~/api/client/APIError/types'
import {TInvalidArgumentsError} from '~/api/client/APIError/types'

/**
 * An error we get from MWB for a certain class of JSON-parsing errors.
 * The response from the backend for these is particularly bad / not useful.
 *
 * Example JSON body:
 *
 *
 * {
 *  "message": "Invalid Arguments",
 *  "errors": [
 *    "When parsing the constructor TOTPPassword of type Import.TotpCommon.TOTPPassword expected Object but got Number."
 *  ]
 * }
 */
export default class APIInvalidArgumentsError extends APIError
  implements IAPIErrorDataInvalidArguments {
  public readonly kind = 'invalidArguments'
  public readonly invalidArgumentsError: TInvalidArgumentsError

  constructor(errorData: IAPIErrorDataInvalidArguments) {
    super(errorData)
    this.invalidArgumentsError = errorData.invalidArgumentsError
  }

  public cleanErrors() {
    return this.invalidArgumentsError.errors.map(msg => {
      // remove the haskell file name from the beginning of the error message, and newlines
      return msg.replace(/^.+?\.hs:\s/i, '').replace('\n', ' ')
    })
  }

  public toHumanString(): string {
    return this.cleanErrors().join(' ')
  }
}
