import Axios, {AxiosPromise, CancelToken} from 'axios'
import {
  IJSONObjectConvertible,
  toJSON,
  TProgressEventHandler,
} from '~/api/client/types'

const defaultHeaders = {
  'content-type': 'application/json',
  'accept': 'application/json; charset=utf-8',
}

export interface IAxiosHTTPClient {
  post: (
    url: string,
    params: IJSONObjectConvertible,
    cancelToken?: CancelToken
  ) => AxiosPromise<any>
  get: (url: string, cancelToken?: CancelToken) => AxiosPromise<any>
  delete: (
    url: string,
    params: IJSONObjectConvertible,
    cancelToken?: CancelToken
  ) => AxiosPromise<any>
  // This is specifically about S3, not more generic, because S3 upload URLs are signed by the server
  // So they can't have e.g. arbitrary headers added to them
  uploadToS3: (
    url: string,
    binary: Blob,
    headers: any,
    cancelToken?: CancelToken,
    uploadProgressHandler?: TProgressEventHandler
  ) => AxiosPromise<any>
  // Used for e.g. sending a file to the backend for image conversion
  processBlob: (
    url: string,
    blob: Blob,
    cancelToken?: CancelToken,
    uploadProgressHandler?: TProgressEventHandler
  ) => AxiosPromise<Blob>
}

export const axiosHTTPClient = (host: string): IAxiosHTTPClient => ({
  post: (endpoint: string, params = {}, cancelToken?: CancelToken) =>
    Axios({
      url: `${host}${endpoint}`,
      method: 'post',
      data: toJSON(params),
      headers: defaultHeaders,
      withCredentials: true,
      cancelToken,
    }),
  get: (endpoint: string, cancelToken?: CancelToken) =>
    Axios({
      url: `${host}${endpoint}`,
      method: 'get',
      headers: defaultHeaders,
      withCredentials: true,
      cancelToken,
    }),
  delete: (endpoint, params = {}, cancelToken?: CancelToken) =>
    Axios({
      url: `${host}${endpoint}`,
      method: 'delete',
      data: toJSON(params),
      headers: defaultHeaders,
      withCredentials: true,
      cancelToken,
    }),
  uploadToS3: (
    url: string,
    binary: Blob,
    headers: any,
    cancelToken?: CancelToken,
    uploadProgressHandler?: TProgressEventHandler
  ) =>
    Axios({
      url,
      method: 'put',
      data: binary,
      headers,
      cancelToken,
      onUploadProgress: uploadProgressHandler,
    }),
  processBlob: (
    endpoint: string,
    blob: Blob,
    cancelToken?: CancelToken,
    uploadProgressHandler?: TProgressEventHandler
  ) => {
    return Axios({
      url: `${host}${endpoint}`,
      method: 'post',
      data: blob,
      headers: {
        'accept': '*/*',
        // Note: If not set, a default form content type is used
        // This causes the server to not read the body correctly.
        'Content-Type': 'application/octet-stream',
      },
      cancelToken,
      withCredentials: true,
      onUploadProgress: uploadProgressHandler,
      responseType: 'blob',
    })
  },
})
