import * as React from 'react'
import {Omit} from 'type-zoo'
import ProvideFormBase, {
  IFieldValues,
  IGenerateFormFieldProps,
  IProvideFormBaseProps,
  TProvideFormIncrementalConfigForBaseForm,
} from '~/components/ProvideForm/base'
import {IFormPropsBase} from './types'

/**
 * These fields are passed from ProvideForm to form fields via the toFormFields provider.
 * This type is used for ProvideFormIncremental but not ProvideForm (the normal one)
 */
export interface IFormPropsIncremental<
  FormFieldsType extends IFieldValues,
  SubmissionContextType = undefined
> extends IFormPropsIncrementalFromBaseForm<FormFieldsType, SubmissionContextType> {
  setIncrementalSubmitCompleteCallback: (callback: () => void) => void
  isIncrementalSubmitCompleteCallbackPending: boolean
}

// IFormPropsIncremental is the publicly-facing stuff given to form fields via toFormFields.
// however, ProvideFormBase can only provide so much of that stuff, which is defined here.
// ProvideFormIncremental adds to this for it's users via it's own props & state.
export interface IFormPropsIncrementalFromBaseForm<
  FormFieldsType extends IFieldValues,
  SubmissionContextType = undefined
> extends IFormPropsBase<FormFieldsType, SubmissionContextType> {
  areAnyFieldsSubmitting: boolean
}

export type TProvideFormOnSubmitIncremental<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> = (
  formField: keyof FormFieldsType,
  value: string,
  submissionContext?: SubmissionContextType
) => Promise<PromiseResultType>

export type TProvideFormToFormFieldsIncremental<
  FormFieldsType extends IFieldValues,
  SubmissionContextType
> = (
  generateFormFieldProps: IGenerateFormFieldProps<
    FormFieldsType,
    SubmissionContextType
  >,
  formProps: IFormPropsIncremental<FormFieldsType, SubmissionContextType>
) => JSX.Element | JSX.Element[]

export type TProvideFormToFormFieldsIncrementalForBaseForm<
  FormFieldsType extends IFieldValues,
  SubmissionContextType
> = (
  generateFormFieldProps: IGenerateFormFieldProps<
    FormFieldsType,
    SubmissionContextType
  >,
  formProps: IFormPropsIncrementalFromBaseForm<FormFieldsType, SubmissionContextType>
) => JSX.Element | JSX.Element[]

// to form the IProps for ProvideForm we take the props of the base and
// first remove the two "generic" versions that work for ProvideForm and ProvideFormIncremental
// then we respecify the props for those two for the specific version that works only with ProvideForm
interface IProps<
  FormFieldsType extends IFieldValues,
  PromiseResultType,
  SubmissionContextType
> extends Omit<
    IProvideFormBaseProps<FormFieldsType, PromiseResultType, SubmissionContextType>,
    'formConfig' | 'submitSuccess' | 'submitFailure'
  > {
  // ^^ submitSuccess, submitFailure are not supported in incremental forms
  onSubmit: TProvideFormOnSubmitIncremental<
    FormFieldsType,
    PromiseResultType,
    SubmissionContextType
  >
  toFormFields: TProvideFormToFormFieldsIncremental<
    FormFieldsType,
    SubmissionContextType
  >
}

interface IState {
  incrementalSubmitCompleteCallback: (() => void) | undefined
}

export default class ProvideFormIncremental<
  FormFieldsType extends IFieldValues,
  PromiseResultType = any, // TODO we should try and remove this `= any` clause by typing every Form's promise.
  SubmissionContextType = undefined
> extends React.Component<
  IProps<FormFieldsType, PromiseResultType, SubmissionContextType>,
  IState
> {
  state: IState = {
    incrementalSubmitCompleteCallback: undefined,
  }

  render() {
    const {onSubmit, toFormFields, ...provideFormProps} = this.props
    const formConfig: TProvideFormIncrementalConfigForBaseForm<
      FormFieldsType,
      PromiseResultType,
      SubmissionContextType
    > = {
      type: 'incremental',
      submit: onSubmit,
      toFormFields: (generateFormFieldProps, formProps) => {
        // add the additional stuff to `formProps` that we want to add
        return toFormFields(generateFormFieldProps, {
          ...formProps,
          setIncrementalSubmitCompleteCallback: cb => {
            if (formProps.anyErrors) {
              // the incrementalSubmitComplete callback is cleared if there are any errors (see below)
              // so don't even bother setting it here. (callers can also check this to, e.g., disable buttons
              // that would otherwise trigger this call)
              return
            } else if (formProps.unsubmittedChanges()) {
              this.setState({incrementalSubmitCompleteCallback: cb})
            } else {
              // no reason to wait, all submissions are complete. go ahead and call it now
              cb()
            }
          },
          isIncrementalSubmitCompleteCallbackPending: !!this.state
            .incrementalSubmitCompleteCallback,
        })
      },
    }
    return (
      <ProvideFormBase<FormFieldsType, PromiseResultType, SubmissionContextType>
        {...provideFormProps}
        formConfig={formConfig}
        formStatus={formStatus => {
          provideFormProps.formStatus?.(formStatus)

          if (this.state.incrementalSubmitCompleteCallback) {
            const isComplete =
              !formStatus.anyErrors &&
              !formStatus.unsubmittedChanges &&
              !formStatus.areAnyFieldsSubmitting

            if (isComplete) {
              const callback = this.state.incrementalSubmitCompleteCallback
              this.setState({incrementalSubmitCompleteCallback: undefined}, callback)
            } else if (
              formStatus.areAnyFieldsChangedAndNotSubmitting ||
              formStatus.anyErrors
            ) {
              // error or changes, so wipe the callback
              this.setState({incrementalSubmitCompleteCallback: undefined})
            }
          }
        }}
      />
    )
  }
}
