import APIError from '~/api/client/APIError/base'
import {IAPIErrorDataLoggedOut} from '~/api/client/APIError/types'

/** This error is received when hitting an endpoint that requires an active session while logged out. */
export default class APILoggedOutError extends APIError
  implements IAPIErrorDataLoggedOut {
  public readonly kind = 'loggedOut'

  constructor(errorData: IAPIErrorDataLoggedOut) {
    super(errorData)
  }

  public toHumanString(): string {
    return 'You are not logged in.'
  }
}
