import * as R from 'ramda'

export default abstract class Model {}

/** this is an interface for the API objects that can be used to build Models. */
type DataBackedModelDatasource = {
  [key: string]: any
}

/**
 * Gets all the property names of a type where the name is a string and the value is a string.
 *
 * the `Extract<keyof T, string>` pieces enforce that the property key is a string.
 * Necessary because the definition of TObject has a index signature only for string keys.
 * (`keyof` is `string | number | symbol` as of TS 2.9)
 *
 * the `T[K] extends string ? K : never` piece excludes properties whose values aren't strings.
 *
 * @see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
 */
// type StringPropertyNames<T> = {
//   [K in Extract<keyof T, string>]: T[K] extends string ? K : never
// }[Extract<keyof T, string>]

export abstract class DataBackedModel<
  DatasourceType extends DataBackedModelDatasource
> {
  datasource: DatasourceType

  // assign the key/value pairs from datasource as properties of the concrete subclass automatically
  constructor(datasource: DatasourceType) {
    if ('datasource' in datasource) {
      // the user passed a model object to the constructor, which works b/c the
      // model `implements` the TObject type. avoid reporting an error in this
      // case and just use the TObject from the model as the user intended.
      datasource = datasource.datasource
    }

    R.forEachObjIndexed((value: any, key: keyof DatasourceType) => {
      if (typeof key !== 'string') {
        // return
      }

      if (key in this) {
        const errorMessage = `The model class already defines a property/method with the
        key '${key}'. The value will not be set on the model.`

        console.error(errorMessage)
      } else {
        // disabling this until `declare` works? it doesn't work...
        // // @ts-ignore
        // this[key] = value
        // Object.assign(this, {[key]: value})
      }
    }, datasource)

    this.datasource = datasource
  }
}
