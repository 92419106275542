import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type AuthState = {
  // admin: boolean

  // Keeps track of the last time we refreshed our server session timer.
  // (the server logs us out after a certain time window. See LogoutTimer).
  lastAuthorizedAt: Date | undefined
  // If an async request returns a signed-out error, setting this to `true` will trigger the auto-sign-out flow.
  loggedOutRemotely: boolean
}

const initialState: AuthState = {
  lastAuthorizedAt: undefined,
  loggedOutRemotely: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signinComplete(state: AuthState, action: PayloadAction<void>) {
      return {lastAuthorizedAt: new Date(), loggedOutRemotely: false}
    },
    restartSessionTimer(state) {
      return {...state, lastAuthorizedAt: new Date()}
    },
    loggedOutRemotely(state) {
      return {...state, loggedOutRemotely: true}
    },
  },
})

const {signinComplete, restartSessionTimer, loggedOutRemotely} = authSlice.actions
export {signinComplete, restartSessionTimer, loggedOutRemotely}
export default authSlice.reducer
