import classnames from 'classnames'
import * as React from 'react'
import LoadingDots from '~/components/LoadingDots'
import {unreachableCase} from '~/utils'
import styles from './styles.module.css'

export type TLoadingState = 'loading' | 'success' | 'failed'

interface IProps {
  loadState: TLoadingState
  classNames?: {
    loading?: string
    failed?: string
  }
  children: React.ReactNode | React.ReactNode[]
  /** By default, this will show an error message on failure. Set `true` to have your own failure UI */
  renderChildrenOnFailure?: boolean
}

class LoadingScreen extends React.Component<IProps> {
  render() {
    switch (this.props.loadState) {
      case 'failed':
        if (!this.props.renderChildrenOnFailure) {
          return (
            <div className={this.props.classNames?.failed}>
              We’re having trouble connecting to our server. Please try refreshing
              the page.
            </div>
          )
        }
        break

      case 'loading':
        return (
          <LoadingDots
            className={classnames([
              styles['loading'],
              this.props.classNames?.loading,
            ])}
          />
        )

      case 'success':
        break

      default: {
        return unreachableCase(this.props.loadState)
      }
    }

    return this.props.children
  }
}

export default LoadingScreen
