import APIError from '~/api/client/APIError/base'
import {IAPIErrorDataInvalidCSRF} from '~/api/client/APIError/types'

/**
 * This error represents a situation where a CSRF token was either missing or expired.
 * Detecting this is useful to re-try a login attempt (the first failure refreshes the CSRF token).
 */
export default class APIInvalidCSRFError extends APIError
  implements IAPIErrorDataInvalidCSRF {
  public readonly kind = 'invalidCSRF'

  constructor(errorData: IAPIErrorDataInvalidCSRF) {
    super(errorData)
  }

  public toHumanString(): string {
    return 'Invalid CSRF token.'
  }
}
