import {PostApi} from '~/api/client'
import APIInvalidCSRFError from '~/api/client/APIError/APIInvalidCSRFError'
import APIError from '~/api/client/APIError/base'
import globalDataMock from '~/api/endpoints/GetInitialData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TGetInitialDataResponse} from '~/api/generated/types/GetInitialData'
import {TJSONError} from '~/api/types'

export type TSignInResponse = TGetInitialDataResponse

export type TSignInParams = {
  email: string
  password: string
}

/**
 * On successful login, this returns GetInitialData
 */
export default class SignIn extends PostApi<TSignInParams, TSignInResponse, {}> {
  protected mockRequestFailure(
    params: TSignInParams,
    pathPieces: {}
  ): TJSONError | null {
    if (params.password === 'failfailfail') {
      return {errors: {password: [`That password isn't right.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(params: TSignInParams): TSignInResponse {
    return globalDataMock
  }

  protected getPath() {
    return serverEndpoints.signin()
  }

  protected transformResponse(r: TSignInResponse) {
    return r
  }

  protected automaticallyRetry(error: APIError, failedAttempts: number): boolean {
    // the CSRF token can expire when users are automatically logged out
    // just retry once (the first try will refresh the CSRF token) for good measure.
    return error instanceof APIInvalidCSRFError && failedAttempts < 2
  }
}
