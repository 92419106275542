import * as R from 'ramda'
import {unreachableCase} from '~/utils/index'

export type Tense =
  | 'past' // Foo `charged` Bar rent
  | 'future' // You can `charge` Bar rent
  | 'presentContinuous' // Foo `is charging` Bar rent
  | 'imperativePrompt' // "`Charge` Bar rent?" or a CTA on a button like "`Charge` Bar rent"

/** Add an 's' to the end of the word if n !== 1 */
export const pluralize = (n: number, singular: string) =>
  singular + (n === 1 ? '' : 's')

/** Should a word be prefixed with 'a' or 'an'? Dumb implementation for now. */
export const indefiniteArticleOf = (word: string): string => {
  return word.match(/^[aeiou]/i) === null ? 'a' : 'an'
}

export const withIndefiniteArticle = (word: string): string => {
  return `${indefiniteArticleOf(word)} ${word}`
}

export const withOrdinalSuffix = (i: number): string => {
  return `${i}${ordinalSuffixOf(i)}`
}

//  https://stackoverflow.com/a/13627586/1176156
export const ordinalSuffixOf = (i: number): string => {
  const j = i % 10
  const k = i % 100

  if (j === 1 && k !== 11) {
    return 'st'
  }
  if (j === 2 && k !== 12) {
    return 'nd'
  }
  if (j === 3 && k !== 13) {
    return 'rd'
  }
  return 'th'
}

export type GameWord =
  | 'Play'
  | 'play'
  | 'Discard'
  | 'discard'
  | 'Move'
  | 'move'
  | 'Place'
  | 'place'
  | 'Charge'
  | 'charge'
  | 'Accept'
  | 'accept'
  | 'Pay'
  | 'pay'
  | 'RespondTo'
  | 'respondTo'

export const inTense = (
  tense: Tense
  // options: {case: 'lower' | 'sentence'} = {case: 'lower'}
) => (word: GameWord): string => {
  let w: string = word
  switch (word) {
    case 'play':
    case 'Play':
    case 'discard':
    case 'Discard':
    case 'Accept':
    case 'accept':
      {
        switch (tense) {
          case 'past':
            w += 'ed'
            break
          case 'imperativePrompt':
          case 'future':
            break
          case 'presentContinuous':
            w = `is ${w}ing`
            break
          default:
            unreachableCase(tense)
        }
      }
      break
    case 'move':
    case 'Move':
    case 'place':
    case 'Place':
    case 'Charge':
    case 'charge':
      {
        switch (tense) {
          case 'past':
            w += 'd'
            break
          case 'imperativePrompt':
          case 'future':
            break
          case 'presentContinuous':
            w = `is ${R.dropLast(1, w)}ing`
            break
          default:
            unreachableCase(tense)
        }
      }
      break
    case 'Pay':
    case 'pay': {
      let lower
      switch (tense) {
        case 'future':
          lower = 'will have to pay'
          break
        case 'past':
          lower = 'paid'
          break
        case 'presentContinuous':
          lower = 'is paying'
          break
        case 'imperativePrompt':
          lower = 'pay'
          break
        default:
          return unreachableCase(tense)
      }
      w = word === 'Pay' ? sentenceCase(lower) : lower
      break
    }
    case 'RespondTo':
    case 'respondTo': {
      let lower
      switch (tense) {
        case 'future':
          lower = 'will respond to'
          break
        case 'past':
          lower = 'responded to'
          break
        case 'presentContinuous':
          lower = 'is responding to'
          break
        case 'imperativePrompt':
          lower = 'respond to'
          break
        default:
          return unreachableCase(tense)
      }
      w = word === 'RespondTo' ? sentenceCase(lower) : lower
      break
    }
    default:
      unreachableCase(word)
  }

  return w
}

/**
 * Returns pieces of a sentence that describe X performing an action to/against/towards Y.
 * X or Y (or neither) can be the observer (`observerId`) to affect the noun & verb conjugation.
 *
 * e.g.
 * (past, foo, charge, bar, undefined) => `Foo charged Bar with their`
 * (presentContinuous, foo, charge, bar, undefined) => `Foo is charging Bar with their`
 *
 * (past, foo, charge, bar, foo) => `You charged Bar with your`
 * (presentContinuous, foo, charge, bar, bar) => `Foo is charging you with their`
 */
export const xVerbY = (
  tense: Tense,
  x: {username: string; id: string},
  verb: GameWord,
  y: {username: string; id: string},
  observerId: string | undefined
): {
  possessivePronoun: string
  xNoun: string
  yNoun: string
  conjugatedVerb: string
  xNounPossessive: string
  yNounPossessive: string
} => {
  let xNoun = x.username
  let conjugatedVerb = inTense(tense)(verb)
  let yNoun = y.username
  let possessivePronoun = 'their'
  let xNounPossessive = `${x.username}'s`
  let yNounPossessive = `${y.username}'s`

  if (y.id === observerId) {
    // Y is the target of the verb, and the observer
    yNoun = 'you'
    yNounPossessive = 'your'
  } else if (x.id === observerId) {
    // X is the actor, and the observer
    xNoun = 'you'
    conjugatedVerb = conjugatedVerb.replace(/^is\s/, 'are ')
    possessivePronoun = 'your'
    xNounPossessive = 'your'
  }

  return {
    possessivePronoun,
    xNoun,
    yNoun,
    conjugatedVerb,
    xNounPossessive,
    yNounPossessive,
  }
}

export const sentenceCase = (sentence: string): string =>
  `${sentence.charAt(0)?.toUpperCase()}${sentence.slice(1)}`

export const show$ = (mil: number) => `$${mil}M`
