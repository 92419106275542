import Axios, {AxiosResponse, CancelToken} from 'axios'
import * as QueryString from 'query-string'
import * as R from 'ramda'
import {buildGetApiPromisePoller} from '~/api/APIPoller/Get'
import {buildPostApiPromisePoller} from '~/api/APIPoller/Post'
import {buildGetApiPromiseManager} from '~/api/ApiPromiseManager/Get'
import {buildPostApiPromiseManager} from '~/api/ApiPromiseManager/Post'
import APILoggedOutError from '~/api/client/APIError/APILoggedOutError'
import APIError from '~/api/client/APIError/base'
import {
  buildFromAxiosError,
  constructMockRequestFailure,
} from '~/api/client/APIError/builder'
import {axiosHTTPClient, IAxiosHTTPClient} from '~/api/client/axios'
import {backendHosts, IJSONObject, IJSONObjectConvertible} from '~/api/client/types'
import MockConfig from '~/api/mocks/config'
import {TJSONError} from '~/api/types'
import environment, {inTesting} from '~/config/environment'
import {loggedOutRemotely, restartSessionTimer} from '~/redux/reducers/auth'
import store from '~/redux/store'
import {convertNullsToUndefined} from '~/utils'
import {ordinalSuffixOf} from '~/utils/language'

export interface IGetQueryParameters {
  [key: string]: string
}

/* eslint-disable-next-line no-shadow */
export enum Method {
  GET,
  POST,
}

export interface IApiPathPieces {
  [k: string]: string | undefined
}

const DEFAULT_MOCK_DELAY = 500

// This is an abstract and never exported class
// Use the GetApi and PostApi classes below
abstract class BaseApiClient<
  PostRequestParamsType extends IJSONObjectConvertible | undefined,
  GetRequestParamsType extends IGetQueryParameters | undefined,
  ResponseType extends IJSONObject,
  PathPiecesType extends IApiPathPieces = {},
  ResultType = ResponseType,
  UnprocessedPostRequestParamsType = PostRequestParamsType
> {
  httpClient: IAxiosHTTPClient

  constructor() {
    const backendSource = environment.backendSource
    this.httpClient = axiosHTTPClient(
      backendSource === 'mock' ? '' : backendHosts[backendSource]
    )
  }

  protected abstract getMethod(): Method
  protected abstract getPath(pathPieces: PathPiecesType): string

  /**
   * Returns a mocked response, optionally configured based on the request if your mock is fancy like that.
   * @param {PostRequestParamsType | GetRequestParamsType} params Your mock response can vary based on the request body
   * @param {PathPiecesType} pathPieces Your mock response may also vary based on params passed in the URL
   * @returns {ResponseType}
   */
  protected abstract getMockResponse(
    params: PostRequestParamsType | GetRequestParamsType | undefined,
    pathPieces: PathPiecesType
  ): ResponseType

  /**
   * Override this in a subclass if you'd like to be able to pre-process the params before they are sent to the server.
   * @param {UnprocessedPostRequestParamsType} params
   * @returns {PostRequestParamsType} The processed params. The default implementation doesn't modify them.
   */
  protected preProcessPostParams(
    params: UnprocessedPostRequestParamsType | undefined
  ): PostRequestParamsType | undefined {
    // have to force a conversion here. Otherwise one would have to overwrite
    // it on every simple API. Bit unfortunate since this conversion
    // is not type safe. But I think it's a reasonable compromise
    return params as PostRequestParamsType
  }

  /**
   * Converts the server response to a type more convenient to your consumers
   * @param {ResponseType} The response type from the server
   * @returns {ResultType} The result type for the apis consumers
   */
  protected abstract transformResponse(
    response: ResponseType,
    params: PostRequestParamsType | GetRequestParamsType | undefined,
    pathPieces: PathPiecesType
  ): ResultType

  /**
   * Whether or not a successful server response should reset the session timer
   * that tracks how much longer the user is logged in.
   * Override this in a subclass for an API that should not reset the session timer.
   */
  protected successShouldResetSessionTimer(result: ResultType): boolean {
    return true
  }

  /**
   * Returns whether or not the API should automatically retry given the error received & number of previous attempts.
   * Default: no retries.
   * Override this in a subclass for an API that wants retries.
   */
  protected automaticallyRetry(error: APIError, failedAttempts: number): boolean {
    return false
  }

  /**
   * Override this in a subclass if you'd like to be have mocks return failures sometimes.
   * @param {PostRequestParamsType | GetRequestParamsType} params
   * @param {PathPiecesType} pathPieces
   * @returns {TJSONError | null} The error object, if the mock should fail, or `null`
   *  if it should succeed. The default implementation returns `null` (no errors)
   */
  protected mockRequestFailure(
    params: PostRequestParamsType | GetRequestParamsType | undefined,
    pathPieces: PathPiecesType
  ): TJSONError | null {
    return null
  }

  protected call = (
    getParams: GetRequestParamsType | undefined,
    postParams: UnprocessedPostRequestParamsType | undefined,
    pathPieces: PathPiecesType,
    cancelToken?: CancelToken
  ): Promise<ResultType> => {
    const processedParams:
      | PostRequestParamsType
      | undefined = this.preProcessPostParams(postParams)
    const callPromise = () => {
      if (MockConfig.enabled) {
        return this.callMock(getParams, processedParams, pathPieces, cancelToken)
      } else {
        return this.callServer(getParams, processedParams, pathPieces, cancelToken)
      }
    }

    const promiseSuccessHandler = (r: ResultType): ResultType => {
      if (this.successShouldResetSessionTimer(r)) {
        store.dispatch(restartSessionTimer())
      }

      return r
    }

    // adapted from https://tech.mybuilder.com/handling-retries-and-back-off-attempts-with-javascript-promises/
    const retry = <T>(
      attemptsMadeSoFar: number,
      promiseFn: () => Promise<T>
    ): Promise<T> =>
      promiseFn().catch(e => {
        if (this.automaticallyRetry(e, attemptsMadeSoFar)) {
          console.warn(
            `API failure. Retrying automatically... (${attemptsMadeSoFar}${ordinalSuffixOf(
              attemptsMadeSoFar
            )} retry)`,
            e
          )
          return retry(attemptsMadeSoFar + 1, promiseFn)
        } else {
          return Promise.reject(e)
        }
      })

    return retry(1, callPromise).then(promiseSuccessHandler)
  }

  protected mockDelay() {
    if (MockConfig.globalDelayMs) {
      return MockConfig.globalDelayMs
    }
    return DEFAULT_MOCK_DELAY
  }

  private callMock = (
    getParams: GetRequestParamsType | undefined,
    postParams: PostRequestParamsType | undefined,
    pathPieces: PathPiecesType,
    cancelToken: CancelToken | undefined
  ): Promise<ResultType> => {
    return new Promise<ResultType>((resolve, reject) => {
      console.log(
        `Hitting Mock Endpoint: ${this.constructor.name}`,
        getParams,
        postParams,
        pathPieces
      )
      if (this.mocksRequireLogin() && MockConfig.loggedOut) {
        console.warn('mocks: server logging you out.')
        reject(
          new APILoggedOutError({
            kind: 'loggedOut',
            statusCode: 401,
            statusText: 'Unauthorized',
            rawResponseData: {errors: {loggedOut: ['true']}},
          })
        )
      } else {
        setTimeout(
          () => {
            // if the token has been cancelled then cancelToken.reason
            // is set. If the token is cancelled then we don't want to resolve
            // the mock promise. Which is what also happens in the non mock case
            if (!cancelToken || !cancelToken.reason) {
              const optionalFailure = this.mockRequestFailure(
                getParams || postParams,
                pathPieces
              )

              if (optionalFailure) {
                console.log(`Mock Failure: ${this.constructor.name}`)
                reject(constructMockRequestFailure(optionalFailure))
              } else {
                console.log(`Mock 200: ${this.constructor.name}`)
                resolve(
                  this.transformResponse(
                    this.getMockResponse(getParams ?? postParams, pathPieces),
                    getParams ?? postParams,
                    pathPieces
                  )
                )
              }
            }
          },
          inTesting ? 0 : this.mockDelay()
        )
      }
    })
  }

  private getPathWithParams = (
    pathPieces: PathPiecesType,
    getParams: GetRequestParamsType | undefined
  ) => {
    let path = this.getPath(pathPieces)
    if (!!getParams && !R.isEmpty(getParams)) {
      path = `${path}?${QueryString.stringify(getParams!)}`
    }
    return path
  }

  private callServer = (
    getParams: GetRequestParamsType | undefined,
    postParams: PostRequestParamsType | undefined,
    pathPieces: PathPiecesType,
    cancelToken?: CancelToken
  ) => {
    let path = this.getPathWithParams(pathPieces, getParams)
    let defaultedPostParams: IJSONObjectConvertible = postParams! || {}

    let call =
      this.getMethod() === Method.GET
        ? this.httpClient.get(path, cancelToken)
        : this.httpClient.post(path, defaultedPostParams, cancelToken)

    return new Promise<ResultType>((resolve, reject) => {
      call
        .then(r =>
          resolve(
            this.transformResponse(
              this.processResponse(r),
              getParams ?? postParams,
              pathPieces
            )
          )
        )
        .catch((e: any) => {
          // cancelled promises don't call `reject`, they just never return.
          if (!Axios.isCancel(e)) {
            console.warn('Error getting result from api', e)

            const apiError = buildFromAxiosError(e, path)
            if (apiError instanceof APILoggedOutError) {
              store.dispatch(loggedOutRemotely())
            }
            reject(apiError)
          }
        })
    })
  }

  private processResponse(result: AxiosResponse<ResponseType>): ResponseType {
    // we've decided to convert all JSON `null`s to `undefined`s in the client
    // so we can continue to use the `?:` syntax for optional fields.
    return convertNullsToUndefined(result.data)
  }

  // may be overriden if the specific API call requires login
  public mocksRequireLogin() {
    return false
  }
}

export abstract class GetApi<
  RequestParamsType extends IGetQueryParameters,
  ResponseType extends IJSONObject,
  PathPiecesType extends IApiPathPieces = {},
  ResultType = ResponseType
> extends BaseApiClient<
  undefined,
  RequestParamsType,
  ResponseType,
  PathPiecesType,
  ResultType,
  undefined
> {
  // no support for pre-processing get params right now
  PromiseManager = buildGetApiPromiseManager<
    RequestParamsType,
    ResponseType,
    PathPiecesType,
    ResultType
  >(this) // `this` is the concrete subclass

  // no support for pre-processing get params right now
  PromisePoller = buildGetApiPromisePoller<
    RequestParamsType,
    ResponseType,
    PathPiecesType,
    ResultType
  >(this) // `this` is the concrete subclass

  protected getMethod(): Method {
    return Method.GET
  }
  // if you are looking for the promise manager we dont support that for GET
  // instead just use POST. We have opted to use POST in almost every place

  /**
   * Calls the API.
   * @param {RequestParamsType} params
   * @param {PathPiecesType} pathPieces
   * @param {CancelToken} cancelToken An optional `Axios.CancelToken`; using one allows the
   * caller to cancel the promise before it returns (i.e.: if your React component unmounts before it returns)
   * @returns {Promise<ResponseType extends IJSONObject>}
   */
  get(
    params: RequestParamsType,
    pathPieces: PathPiecesType,
    cancelToken?: CancelToken
  ): Promise<ResultType> {
    return this.call(params, undefined, pathPieces, cancelToken)
  }
}

export abstract class PostApi<
  RequestParamsType extends IJSONObjectConvertible,
  ResponseType extends IJSONObject,
  PathPiecesType extends IApiPathPieces = {},
  ResultType = ResponseType,
  UnprocessedRequestParamsType = RequestParamsType
> extends BaseApiClient<
  RequestParamsType,
  undefined,
  ResponseType,
  PathPiecesType,
  ResultType,
  UnprocessedRequestParamsType
> {
  /** use this whenever we have a loading screen waiting for an API
   * as it properly does the cancellation of the promise
   */
  PromiseManager = buildPostApiPromiseManager<
    RequestParamsType,
    ResponseType,
    PathPiecesType,
    ResultType,
    UnprocessedRequestParamsType
  >(this) // `this` is the concrete subclass

  /** use this whenever we have a loading screen waiting for an API
   * as it properly does the cancellation of the promise
   */
  PromisePoller = buildPostApiPromisePoller<
    RequestParamsType,
    ResponseType,
    PathPiecesType,
    ResultType,
    UnprocessedRequestParamsType
  >(this) // `this` is the concrete subclass

  protected getMethod(): Method {
    return Method.POST
  }

  /**
   * Calls the API.
   * @param {UnprocessedRequestParamsType} params
   * @param {PathPiecesType} pathPieces
   * @param {CancelToken} cancelToken An optional `Axios.CancelToken`; using one allows the
   * caller to cancel the promise before it returns (i.e.: if your React component unmounts before it returns)
   * @returns {Promise<ResponseType extends IJSONObject>}
   */
  post(
    params: UnprocessedRequestParamsType,
    pathPieces: PathPiecesType,
    cancelToken?: CancelToken
  ): Promise<ResultType> {
    return this.call(undefined, params, pathPieces, cancelToken)
  }
}
