import * as DateFns from 'date-fns'
import {IToJSONValue} from '~/api/client/types'
import {recordKeys, unreachableCase} from '~/utils'
import {greaterThan, greaterThanOrEqual} from '~/utils/Comparable'
import * as C from '~/utils/Comparable'
import * as Equatable from '~/utils/Equatable'
import Month from '~/utils/Month'

export const serverDayFormat = 'yyyy-MM-dd'
export const clientDayFormat = 'MM/dd/yyyy'
const urlFormat = 'MM-dd-yyyy' // (Since slashes are escaped in URLs)

export type TWeekday = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday'
export type TWeekend = 'saturday' | 'sunday'

export type TDayOfWeek = TWeekday | TWeekend
export const humanDays: Record<TDayOfWeek, string> = {
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
}

export const daysOfWeek: TDayOfWeek[] = recordKeys(humanDays)
export const allWeekdays: TWeekday[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
]

class Day implements IToJSONValue, C.IComparable<Day>, Equatable.IEquatable {
  day: number
  month: number
  year: number

  constructor(year: number, month: number, day: number) {
    // Months are 1 indexed, vs Javascript's 0-indexed months
    if (month < 1 || month > 12) {
      throw new Error('Invalid month: ' + month)
    }
    this.year = year
    this.month = month
    this.day = day
  }

  toDate = (): Date => {
    return new Date(this.year, this.month - 1, this.day)
  }

  toUTCDate = (): Date => {
    return new Date(Date.UTC(this.year, this.month - 1, this.day))
  }

  addDays = (n: number): Day => {
    return Day.fromDate(new Date(this.year, this.month - 1, this.day + n))
  }

  addMonths = (n: number): Day => {
    // if you do (May 31).addMonths(-1), we want to return the last day of April,
    // not what Date would do by default which would be (April 31) === (May 1), since
    // April only has 30 days.

    // using day 1 of the month to avoid overflowing months, this is fixed below
    let newDay = Day.fromDate(new Date(this.year, this.month - 1 + n, 1))

    if (newDay.toMonth().numberOfDays() < this.day) {
      // e.g. (March 31).addMonths(-1), Feb has 28/29 days. Return last day of month
      newDay = newDay.toMonth().toLastDay()
    } else {
      newDay.day = this.day
    }

    return newDay
  }

  addYears = (n: number): Day => {
    return Day.fromDate(new Date(this.year + n, this.month - 1, this.day))
  }

  subDays = (n: number): Day => this.addDays(-1 * n)
  subMonths = (n: number): Day => this.addMonths(-1 * n)
  subYears = (n: number): Day => this.addYears(-1 * n)

  static fromDateUTC = (date: Date): Day => {
    return new Day(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate())
  }

  static fromDate = (date: Date): Day => {
    return new Day(date.getFullYear(), date.getMonth() + 1, date.getDate())
  }

  toJSONValue = (): string => {
    return this.toServerFormat()
  }

  toServerFormat = (): string => {
    return DateFns.format(this.toDate(), serverDayFormat)
  }

  toISOTime = (): string => {
    return this.toDate().toISOString()
  }

  toISOTimeUTC = (): string => {
    return this.toUTCDate().toISOString()
  }

  toClientFormat = (): string => {
    return DateFns.format(this.toDate(), clientDayFormat)
  }

  toURLFormat = (): string => {
    return DateFns.format(this.toDate(), urlFormat)
  }

  toFancyClientFormat = (
    withoutYear?: boolean,
    todayOptions?: 'abr-and-date' | 'abr' | 'date'
  ): string => {
    const dateStr = DateFns.format(
      this.toDate(),
      withoutYear ? 'MMM d' : 'MMM d, yyyy'
    )
    if (C.equals(this, Day.today())) {
      switch (todayOptions) {
        case 'date':
          return dateStr
        case 'abr-and-date':
          return 'Today, ' + dateStr
        case 'abr':
        case undefined:
          return 'Today'
        default:
          return unreachableCase(todayOptions)
      }
    } else {
      return dateStr
    }
  }

  format = (formatString: string): string => {
    return DateFns.format(this.toDate(), formatString)
  }

  toMonth = (): Month => {
    return new Month(this.year, this.month)
  }

  compare = (day: Day): C.CompareResult => {
    return C.compareArrays(this.toComponents(), day.toComponents())
  }

  isAfter = (day: Day): boolean => {
    return greaterThan(this, day)
  }

  isAfterOrEqual = (day: Day): boolean => {
    return greaterThanOrEqual(this, day)
  }

  isBefore = (day: Day): boolean => {
    return greaterThan(day, this)
  }

  isBeforeOrEqual = (day: Day): boolean => {
    return greaterThanOrEqual(day, this)
  }

  /** Saturday - Sunday */
  isWeekend = (): boolean => {
    const idx = this.dayOfWeekIndex()
    return idx === 0 || idx === 6
  }

  /** Monday - Friday */
  isWeekday = (): boolean => !this.isWeekend()

  // This method can be called by Ramda which will ignore the type required of `other`
  // so we check this manually at run-time. However, using `any` for the type declaration isn't useful for our own code.
  equals = (other: Day): boolean => {
    if (Day.isDay(other)) {
      return this.compare(other) === C.CompareResult.EQ
    } else {
      return false
    }
  }

  clone = (): Day => {
    return new Day(this.year, this.month, this.day)
  }

  toComponents() {
    return [this.year, this.month, this.day]
  }

  dayOfWeek(): TDayOfWeek {
    return daysOfWeek[this.dayOfWeekIndex()]
  }

  // Sunday = 0, Monday = 1, ..., Saturday = 6
  dayOfWeekIndex(): number {
    return this.toDate().getDay()
  }

  dayOfWeekHuman(): string {
    return humanDays[this.dayOfWeek()]
  }

  static isDay = (obj: any): obj is Day => {
    return !!obj && obj instanceof Day
  }

  static fromClient(s: string): Day | undefined {
    const parsed = DateFns.parse(s, clientDayFormat, new Date())
    if (isNaN(parsed.getDate())) {
      return undefined
    } else {
      return Day.fromDate(parsed)
    }
  }

  static fromClientThrows(s: string): Day {
    const maybeDay = this.fromClient(s)
    if (maybeDay) {
      return maybeDay
    } else {
      throw new Error('Invalid Day: ' + s)
    }
  }

  static fromURLFormat(s: string): Day | undefined {
    const parsed = DateFns.parse(s, urlFormat, new Date())
    if (isNaN(parsed.getDate())) {
      return undefined
    } else {
      return Day.fromDate(parsed)
    }
  }

  static maybeFromServer(s: string): Day | undefined {
    const parsed = DateFns.parse(s, serverDayFormat, new Date())
    if (isNaN(parsed.getDate())) {
      return undefined
    } else {
      return Day.fromDate(parsed)
    }
  }

  static fromServer(s: string) {
    return Day.fromDate(DateFns.parse(s, serverDayFormat, new Date()))
  }

  static fromISOTime(s: string) {
    return Day.fromDate(new Date(s))
  }

  static fromISOTimeUTC(s: string) {
    return Day.fromDateUTC(new Date(s))
  }

  static today(): Day {
    return Day.fromDate(new Date())
  }

  // Use the current year/month/date from the UTC time zone to construct a Day object
  // If it is 9pm -7 PST on the 2nd locally, it is actually the 3rd in UTC. The Day returned would be the 3rd.
  // If it is 3pm -7 PST on the 2nd locally, it is also the 2nd in UTC. The Day returned would be the 2nd.
  static todayUTC(): Day {
    return Day.fromDateUTC(new Date())
  }

  static yesterday(): Day {
    return Day.fromDate(DateFns.subDays(new Date(), 1))
  }

  static tomorrow(): Day {
    return Day.fromDate(DateFns.addDays(new Date(), 1))
  }

  static theDayAfterTomorrow(): Day {
    return Day.tomorrow().nextDay()
  }

  static compare(a: Day, b: Day): -1 | 0 | 1 {
    return C.compareArrays(a.toComponents(), b.toComponents())
  }

  nextDay(): Day {
    return Day.fromDate(DateFns.addDays(this.toDate(), 1))
  }

  previous(dayOfWeek: TDayOfWeek): Day {
    const thisIdx = daysOfWeek.indexOf(this.dayOfWeek())
    const thatIdx = daysOfWeek.indexOf(dayOfWeek)

    let offset = thisIdx + 7 - thatIdx
    if (offset > 7) {
      offset = offset % 7
    }
    return this.subDays(offset)
  }

  next(dayOfWeek: TDayOfWeek): Day {
    const thisIdx = daysOfWeek.indexOf(this.dayOfWeek())
    const thatIdx = daysOfWeek.indexOf(dayOfWeek)

    let offset = thatIdx + 7 - thisIdx
    if (offset > 7) {
      offset = offset % 7
    }
    return this.addDays(offset)
  }

  // used by JS to facilitate the comparison operators i.e. `<` `>=`, but notably not for `==` nor `===`
  valueOf(): number {
    return this.toDate().getTime()
  }
}

export default Day
